<template>
  <div id="app" class="min-h-screen">
    <router-view />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useThemeStore } from '@/stores/theme'

const themeStore = useThemeStore()

onMounted(() => {
  themeStore.initTheme()
})
</script>

<style>
@import './style.css';

#app {
  min-height: 100vh;
  @apply bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100;
}
</style> 