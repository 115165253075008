import { createApp } from 'vue';
import { createPinia } from 'pinia';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import router from './router';
import App from './App.vue';
import './style.css';
import { useAuthStore } from '@/stores/auth';

async function initApp() {
  const app = createApp(App);
  const pinia = createPinia();

  app.use(pinia);
  app.use(router);
  app.use(ElementPlus);

  // Initialize auth state before mounting
  const authStore = useAuthStore();
  await authStore.initAuth();

  app.mount('#app');
}

initApp().catch(console.error); 