import { defineStore } from 'pinia'

// Hardcoded user data
const DEMO_USER = {
  id: '1',
  email: 'andy.mi@unisco.com',
  companyId: '1',
  isAdmin: false,
  lastLogin: new Date().toISOString()
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null as typeof DEMO_USER | null,
    isAuthenticated: false,
    isLoading: false,
    error: null as string | null
  }),

  getters: {
    isAdmin: (state) => state.user?.isAdmin || false,
    companyId: (state) => state.user?.companyId
  },

  actions: {
    setUser(user: typeof DEMO_USER | null) {
      this.user = user
      this.isAuthenticated = !!user
      
      if (user) {
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        localStorage.removeItem('user')
      }
    },

    async initAuth() {
      // Always set the demo user
      this.setUser(DEMO_USER)
    },

    async signIn() {
      this.isLoading = true
      try {
        // Always return demo user
        this.setUser(DEMO_USER)
        return DEMO_USER
      } finally {
        this.isLoading = false
      }
    },

    async signOut() {
      this.setUser(null)
    },

    async adminSignIn() {
      throw new Error('Admin sign in not available in demo mode')
    }
  }
}) 