import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { ElMessage } from 'element-plus'

// Marketing site routes
const marketingRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/views/marketing/layout.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/marketing/home.vue')
      },
      {
        path: 'pricing',
        name: 'Pricing',
        component: () => import('@/views/marketing/pricing.vue')
      },
      {
        path: 'solutions',
        name: 'Solutions',
        component: () => import('@/views/marketing/solutions.vue')
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('@/views/marketing/about.vue')
      }
    ]
  }
]

// Auth routes
const authRoutes: RouteRecordRaw[] = [
  {
    path: '/signin',
    name: 'Signin',
    component: () => import('@/views/auth/signin.vue'),
    meta: { requiresNoAuth: true }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/views/auth/signup.vue'),
    meta: { requiresNoAuth: true }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/auth/reset-password.vue'),
    meta: { requiresNoAuth: true },
    props: (route) => ({
      email: route.query.email,
      code: route.query.code
    })
  }
]

// Admin routes
const adminRoutes: RouteRecordRaw[] = [
  {
    path: '/admin/signin',
    name: 'AdminSignin',
    component: () => import('@/views/admin/auth/signin.vue'),
    meta: { requiresNoAuth: true }
  },
  {
    path: '/admin',
    component: () => import('@/views/admin/layout.vue'),
    meta: { requiresAdmin: true },
    children: [
      {
        path: '',
        redirect: '/admin/dashboard'
      },
      {
        path: 'dashboard',
        name: 'AdminDashboard',
        component: () => import('@/views/admin/dashboard.vue')
      },
      {
        path: 'accounts',
        name: 'AdminAccounts',
        component: () => import('@/views/admin/accounts/index.vue')
      },
      {
        path: 'subscriptions',
        name: 'AdminSubscriptions',
        component: () => import('@/views/admin/subscriptions/index.vue')
      }
    ]
  }
]

// Client app routes
const clientRoutes: RouteRecordRaw[] = [
  {
    path: '/app',
    component: () => import('@/views/app/layout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/app/dashboard'
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/index.vue')
      },
      {
        path: 'orders',
        name: 'Orders',
        component: () => import('@/views/orders/layout.vue'),
        children: [
          {
            path: '',
            redirect: { name: 'OrderList' }
          },
          {
            path: 'list',
            name: 'OrderList',
            component: () => import('@/views/orders/order-list.vue')
          },
          {
            path: ':id',
            name: 'OrderDetail',
            component: () => import('@/views/orders/order-detail.vue'),
            props: true
          }
        ]
      },
      {
        path: 'products',
        name: 'Products',
        component: () => import('@/views/products/layout.vue'),
        children: [
          {
            path: '',
            redirect: { name: 'ProductList' }
          },
          {
            path: 'list',
            name: 'ProductList',
            component: () => import('@/views/products/product-list.vue')
          },
          {
            path: 'brands',
            name: 'ProductBrands',
            component: () => import('@/views/products/product-brands.vue')
          },
          {
            path: 'categories',
            name: 'ProductCategories',
            component: () => import('@/views/products/product-categories.vue')
          },
          {
            path: 'supplies',
            name: 'ProductSupplies',
            component: () => import('@/views/products/product-supplies.vue')
          }
        ]
      },
      {
        path: 'inventory',
        name: 'Inventory',
        component: () => import('@/views/inventory/index.vue')
      },
      {
        path: 'purchases',
        name: 'Purchases',
        component: () => import('@/views/purchases/index.vue')
      },
      {
        path: 'returns',
        name: 'Returns',
        component: () => import('@/views/returns/index.vue')
      },
      {
        path: 'appointments',
        name: 'Appointments',
        component: () => import('@/views/appointments/index.vue')
      },
      {
        path: 'employees',
        name: 'Employees',
        component: () => import('@/views/employees/index.vue')
      },
      {
        path: 'assets',
        name: 'Assets',
        component: () => import('@/views/assets/index.vue')
      },
      {
        path: 'channels',
        name: 'Channels',
        component: () => import('@/views/channels/index.vue')
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/views/settings/index.vue')
      }
    ]
  }
]

// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...marketingRoutes,
    ...authRoutes,
    ...adminRoutes,
    ...clientRoutes,
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('@/views/not-found.vue')
    }
  ]
})

// Navigation guards
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()

  // Wait for auth initialization
  if (!authStore.isAuthenticated && (to.meta.requiresAuth || to.meta.requiresAdmin)) {
    await authStore.initAuth()
  }

  // Allow reset password route even when authenticated
  if (to.name === 'ResetPassword') {
    next()
    return
  }

  // Handle admin routes
  if (to.meta.requiresAdmin) {
    if (!authStore.isAuthenticated) {
      next({ name: 'AdminSignin', query: { redirect: to.fullPath } })
      return
    }
    if (!authStore.isAdmin) {
      ElMessage.error('Access denied. Admin privileges required.')
      next({ name: 'Signin' })
      return
    }
  }

  // Handle protected routes
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    next({ name: 'Signin', query: { redirect: to.fullPath } })
    return
  }

  // Handle auth pages when already authenticated
  if (authStore.isAuthenticated && to.meta.requiresNoAuth && to.name !== 'ResetPassword') {
    if (authStore.isAdmin) {
      next({ name: 'AdminDashboard' })
    } else {
      next({ name: 'Dashboard' })
    }
    return
  }

  next()
})

export default router 